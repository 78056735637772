<template>
    <el-dialog
    v-model="dialogVisible"
    title="新增文物"
    width="500px"
    >
    <el-form
    ref="form"
    :model="ruleForm"
    label-width="60px"
    >
        <el-form-item 
        label="名称" 
        prop="relicname"
        :rules="{ required: true, message: '文物名称不能为空', trigger: 'blur' }">
            <el-input v-model="ruleForm.relicname" />
        </el-form-item>
        <el-form-item 
        label="材质" 
        prop="material"
        :rules="{ required: true, message: '请选择文物材质', trigger: 'blur' }">
            <el-select v-model="ruleForm.material" placeholder="请选择材质">
                <el-option v-for="(item, index) in textureS" :label="item" :value="item" />
            </el-select>
        </el-form-item>
        <el-form-item 
        label="详情" 
        prop="detail"
        :rules="{ required: true, message: '请填写文物详情', trigger: 'blur' }">
            <el-input type="textarea" v-model="ruleForm.detail" />
        </el-form-item>
        <el-form-item 
        label="病害" 
        prop="disease"
        :rules="{ required: true, message: '请填写文物病害', trigger: 'blur' }">
            <el-input type="textarea" v-model="ruleForm.disease" />
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitFun('form')">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { site_relicAdd } from '@/api/site-warning.js'
export default{
    emits:['OK'],
    data(){
        return{
            dialogVisible:false,
            textureS:['馆藏壁画','青铜','铁器','纸质','纺织品','漆木器','其他'],
            ruleForm:{
                relicname:null,
                material:null,
                detail:null,
                disease:null,
                envid:null,
            },
        }
    },
    methods:{
        open(id){
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.$refs.form.resetFields()
                this.ruleForm.envid = id
            })
        },
        submitFun(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    site_relicAdd(this.ruleForm).then(r=>{
                        if(r.code==200){
                            this.$emit("OK")
                        }
                        this.dialogVisible = false
                    })
                    
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__title{
    color: white !important;
}
/deep/.el-dialog__header{
    background-color: rgb(54, 54, 219) !important;
}
</style>